import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Media
import Leaf from 'img/leaf.inline.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  color: ${(props) => props.theme.color.light};
`

const LeafWrapper = styled.div`
  position: absolute;
  right: calc(100% + 15px);
  top: 0;
`

const FooterMenu = styled.div`
  & ul {
    justify-content: flex-end;

    & a:hover {
      text-decoration: underline;
    }

    @media (min-width: 992px) {
      list-style-type: none;
    }
  }
`

const Social = styled.a`
  font-size: 0;
`

const SocialIcon = styled(Plaatjie)`
  height: 35px;
  width: 35px;
`

const FooterMenuLink = styled(Link)`
  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.medium};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.small};
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          text {
            description
          }
          menuFooter {
            link {
              title
              url
            }
          }
          socials {
            icon {
              localFile {
                publicURL
              }
            }
            link {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter className="py-sm-5">
      <div className="container py-5">
        <div className="position-relative">
          <LeafWrapper>
            <Leaf />
          </LeafWrapper>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <ParseContent content={fields?.footer?.text?.description || ''} />
            </div>
            <FooterMenu className="col-sm-6 col-lg-9 mt-5 mt-sm-0">
              <ul className="d-lg-flex">
                {fields?.footer?.menuFooter?.map(
                  ({ link: { url, title } }: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className="ps-lg-4 ps-xl-5">
                      <FooterMenuLink to={url}>{title}</FooterMenuLink>
                    </li>
                  )
                )}
              </ul>
              <div className="d-flex justify-content-end mt-lg-5 pt-lg-5 pt-4">
                {fields?.footer?.socials?.map(
                  (
                    { link: { url, title, target }, icon }: any,
                    index: number
                  ) => (
                    <Social
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className="ms-3"
                      href={url}
                      target={target}
                      rel="noopener noreferrer"
                    >
                      <SocialIcon image={icon} alt={title} />
                      {title}
                    </Social>
                  )
                )}
              </div>
            </FooterMenu>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
