/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'
import parse from 'html-react-parser'
import useMedia from 'use-media'

// Images
import Logo from 'img/logo.inline.svg'
import Leaf from 'img/leaf.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Plaatjie from '@ubo/plaatjie'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: ${(props) => props.theme.color.dark};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid ${({ theme }) => theme.color.light};
    `}

  @media (min-width: 992px) {
    height: 80px;
  }

  @media (max-width: 991px) {
    height: 70px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  font-size: 0;

  & > svg {
    @media (min-width: 1200px) {
      height: 86px;
      width: 132px;
    }

    @media (max-width: 1199px) {
      height: 54px;
      width: 100px;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            hassubmenu
            submenu {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader isSticky={isSticky}>
      <Container className="container">
        <Brand to="/">
          <Logo />
          Home
        </Brand>

        <MenuMobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <MenuDesktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0;
  background: rgb(129, 154, 91);
  background: radial-gradient(
    circle,
    rgba(129, 154, 91, 1) 0%,
    rgba(90, 111, 57, 1) 100%
  );
  overflow-y: auto;

  & ul {
    margin: 0 auto;
    list-style-type: none;
    text-align: center;
    max-width: 450px;
    padding: 25px;
    border-radius: 25px;

    & li {
      padding: 0;

      & a {
        font-size: ${({ theme }) => theme.font.size.large};
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 12px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MenuMobileItems = styled.ul`
  position: relative;
  z-index: 2;
`

const MenuMobileLogo = styled.div``

const MenuMobileLeaf = styled.div`
  position: absolute;
  right: 10px;
  bottom: 50px;
  height: 80%;
  width: 50%;
  z-index: 1;

  & svg {
    height: 100%;
    width: 100%;
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${(props) => props.theme.color.light};
  text-transform: uppercase;
  margin-top: 3px;
`

interface MenuMobileProps {
  fields: any
  isSticky?: boolean
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>Menu</NavButtonTitle>
      </NavButton>
      <AnimatePresence>
        {isOpen && (
          <MenuWrapper
            exit={{
              opacity: 0,
              y: '100%',
            }}
            initial={{
              opacity: 0,
              y: '100%',
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <MenuMobileLogo className="ms-5">
              <Logo />
            </MenuMobileLogo>
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
              <NavButtonCloseTitle>Sluiten</NavButtonCloseTitle>
            </NavButtonClose>
            <MenuMobileItems>
              <Menu fields={fields} />
            </MenuMobileItems>
            <MenuMobileLeaf>
              <Leaf />
            </MenuMobileLeaf>
          </MenuWrapper>
        )}
      </AnimatePresence>
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
  height: 100%;

  @media (min-width: 1400px) {
    padding-left: 50px;
  }

  @media (max-width: 1399px) {
    padding-left: 50px;
  }

  @media (max-width: 1199px) {
    padding-left: 0;
  }
`

interface MenuDesktopProps {
  fields: any
  isSticky?: boolean
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  height: 100%;
  position: relative;
  align-items: center;

  @media (min-width: 992px) {
    display: flex;
  }
`

const ItemLink = styled(NavLink)<{ isButton: boolean }>`
  color: ${(props) => props.theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  height: 100%;
  display: flex;
  align-items: center;

  &[aria-current] {
    @media (min-width: 992px) {
      background-color: ${({ theme }) => theme.color.primary};
    }

    @media (max-width: 991px) {
      text-decoration: underline;
    }

    &:hover {
      color: ${(props) => props.theme.color.light};
    }
  }

  ${(props) =>
    props.isButton &&
    css`
      background-color: ${({ theme }) => theme.color.primary};
      border-radius: 20px;
      height: 40px;

      @media (max-width: 991px) {
        padding: 0 20px !important;
        margin-top: 1rem;
      }
    `};

  &:hover {
    color: ${(props) => props.theme.color.light};
    text-decoration: underline;
  }

  @media (min-width: 1400px){
    font-size: ${({ theme }) => theme.font.size.medium} !important;
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.small};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.small};
  }
`

const ItemArrow = styled(motion.div)`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface MenuProps {
  fields: any
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [indexOpen, setIndexOpen] = React.useState<number | null>(null)
  const isDesktop = useMedia({ minWidth: 992 })

  return (
    <>
      {fields.header.menuHeader.map(
        ({ link: { url, title }, hassubmenu, submenu }: any, index: number) => (
          <Item
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onMouseEnter={() => {
              if (submenu && isDesktop) {
                if (indexOpen === index) {
                  setIndexOpen(null)
                } else {
                  setIndexOpen(index)
                }
              }
            }}
            onMouseLeave={() => {
              if (isDesktop) {
                setIndexOpen(null)
              }
            }}
          >
            <div className="d-flex justify-content-between align-items-center h-100">
              <ItemLink
                to={url}
                className="px-2 px-lg-2 px-xl-3 mx-1"
                isButton={url.indexOf('tel:') !== -1}
              >
                {parse(title)}
              </ItemLink>
              {hassubmenu && (
                <ItemArrow
                  type="button"
                  className="d-lg-none"
                  onClick={() => {
                    if (indexOpen === index) {
                      setIndexOpen(null)
                    } else {
                      setIndexOpen(index)
                    }
                  }}
                  animate={indexOpen === index ? 'open' : 'closed'}
                  variants={{
                    open: {
                      rotate: 180,
                    },
                    closed: {
                      rotate: 0,
                    },
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </ItemArrow>
              )}
            </div>
            {hassubmenu && (
              <AnimatePresence>
                {indexOpen === index && <Submenu menu={submenu} />}
              </AnimatePresence>
            )}
          </Item>
        )
      )}
    </>
  )
}

const StyledSubmenu = styled(motion.div)`
  border-top: 1px solid ${({ theme }) => theme.color.light};
  left: 0;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform: translateZ(0);

  & a {
    font-size: ${(props) => props.theme.font.size.medium} !important;
    color: ${({ theme }) => theme.color.light};
    display: block;

    &[aria-current] {
      @media (min-width: 992px) {
        background-color: ${({ theme }) => theme.color.primary};
      }

      @media (max-width: 991px) {
        text-decoration: underline;
      }
    }

    &:hover {
      color: ${(props) => props.theme.color.light};
      text-decoration: underline;
    }
  }

  @media (min-width: 992px) {
    background-color: ${({ theme }) => theme.color.dark};
    position: absolute;
    top: 100%;
    width: 250px;
  }

  @media (max-width: 991px) {
    background-color: ${({ theme }) => theme.color.primary};
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`

const SubmenuLinkIcon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 991px) {
    width: 15px;
    height: 15px;
  }
`

interface SubmenuProps {
  menu: any
}

const Submenu: React.FC<SubmenuProps> = ({ menu }) => (
  <StyledSubmenu
    exit={{
      y: -10,
      opacity: 0,
      display: 'none',
    }}
    initial={{
      y: -10,
      opacity: 0,
      display: 'none',
    }}
    animate={{
      y: 0,
      opacity: 1,
      display: 'block',
    }}
  >
    {menu.map(({ link, icon }: any, index: number) => (
      <NavLink
        // eslint-disable-next-line react/no-array-index-key
        key={`submenu-${index}`}
        className="px-3 px-lg-4 py-2 py-lg-3 d-flex align-items-center"
        to={link.url}
      >
        <SubmenuLinkIcon className="mr-3" image={icon} alt={link.title} />
        {parse(link.title)}
      </NavLink>
    ))}
  </StyledSubmenu>
)

export default Header
