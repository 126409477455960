const mainTheme = {
  color: {
    primary: '#819A5B',
    dark: '#000000',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'Source Sans Pro, sans-serif',
      secondary: 'Bungee, cursive',
    },

    size: {
      tiny: '12px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '20px',
      huge: '24px',
      mega: '28px',
      giga: '36px',
      ultra: '40px',
    },

    weight: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
  },
}

export default mainTheme
