import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Third party
import Modal from 'react-modal'
import useLayout from 'hooks/useLayout'

// Images
import Cancel from 'img/cancel.inline.svg'
import { useMedia } from 'react-use'
import ImageVacature from 'img/VTTuinen-popup-vacature.png'
import { Link } from 'gatsby'


const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.color.primary};
`

const ModalImage = styled.img`
  border-radius: 24px;

  @media (max-width: 991px){
    width: 400px;
  }
`

const SvgWrapper = styled.div`
  position: relative;
  top: 15px;
  right: 25px;


  & svg {
    width: 25px;
    height: 25px;

    & path {
      fill: ${({ theme }) => theme.color.dark};
    }
  }
`

interface VacancyModalProps {
  setEventModal: Function
}

function VacancyModal({ setEventModal }){
  const [isOpen, setIsOpen] = React.useState(false)

  const mobile = useMedia('(max-width: 575px)')
  
  useEffect(() => {
    setTimeout(() => {
      {!sessionStorage.getItem('hide') && (
        setIsOpen(true)     
      )}
    }, 2000)
  },[])


  function closeModal() {
    sessionStorage.setItem("hide", "true");
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      height: mobile ? '262px' : 'auto',
      width: mobile ? '400px' : '700px',
      overflowY: mobile ? 'scroll' : 'none',
      zIndex: 999,
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '24px',
      padding: '0',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      background: 'rgba(9, 21, 79, 0.25)',
    },
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      // @ts-ignore
      style={customStyles}
    >
      <div>
        <SvgWrapper className="d-flex justify-content-end mb-4">
          <Cancel onClick={closeModal} role="button" />
        </SvgWrapper>
        <Link to="/vacatures/hovenier-onderhoud-en-of-aanleg/">
          <ModalImage src={ImageVacature} width="700" alt="" />
        </Link>
      </div>
    </StyledModal>
  )
}

export default VacancyModal
